<template>
    <fragment>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    class="dealer-orders-table"
                    ref="grid"
                    :resizable="true"
                    :pageable="gridPageable"
                    :sortable="true"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <CButton
                                    @click="cancelOrder(dataItem.id)"
                                    :disabled="!dataItem.can_cancel_order"
                                    class="actions-btn btn-square btn-info"
                                >
                                    <i class="la la-remove"></i>
                                    Отменить
                                </CButton>
                            </div>
                        </td>
                    </template>
                    <template v-slot:status_filter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="statusFilter"
                            :text-field="'name'"
                            :data-item-key="'key'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectStatus"
                        />
                    </template>
                    <template v-slot:date_filter="{ methods, props }">
                        <div class="d-flex align-end">
                            <datepicker
                                :default-show="defaultShow"
                                :start-date-input-settings="startDateInputSettings"
                                :value="value"
                                :end-date-input-settings="endDateInputSettings"
                                @change="dateChangeHandler"
                            />
                            <button
                                @click="clearDates"
                                title="Clear"
                                type="button"
                                class="k-button k-button-icon k-clear-button-visible"
                            >
                                <span class="k-icon k-i-filter-clear"></span>
                            </button>
                        </div>
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{
                                getProductOrderStatusNameByKey(dataItem.status)
                            }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <v-row class="mt-3 mb-2">
            <v-col cols="12" class="pl-0 d-flex justify-end">
                <CButton
                    @click="downloadReport"
                    class="export-excel-btn"
                    color="success"
                >
                    <i class="mr-2 fa fa-save"></i><span>Экспорт в Excel</span>
                </CButton>
            </v-col>
        </v-row>
    </fragment>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import { Grid } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import '@/views/grid_elements/translations';
    import api from '@/api';
    import { showConfirm } from '@/lib/swal2';
    import { showMessage } from '@/lib/toasted';
    import OrderStatuses from '@/config/order_statuses';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { mapActions, mapGetters } from 'vuex';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';
    import { DateRangePicker } from '@progress/kendo-vue-dateinputs';

    export default {
        name: 'DealerOrdersTab',
        components: {
            Grid: Grid,
            localization: LocalizationProvider,
            intl: IntlProvider,
            dropdownlist: DropDownList,
            datepicker: DateRangePicker,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            this.getData();
            this.fetchDealerListShort();
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                defaultShow: false,
                value: {
                    start: null,
                    end: null,
                },
                startDateInputSettings: {
                    label: 'От',
                    format: 'dd.MM.yyyy',
                },
                endDateInputSettings: {
                    format: 'dd.MM.yyyy',
                    label: 'До',
                },
                total: 0,
                sort: [{ field: 'payment_date', dir: 'desc' }],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 160,
                        width: '160px',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'number',
                        title: 'Номер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'payment_date',
                        title: 'Дата заказа',
                        minWidth: 440,
                        width: '440px',
                        format: '{0:d}',
                        filter: 'date',
                        type: 'date',
                        filterCell: 'date_filter',
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        filterCell: 'status_filter',
                        cell: 'status',
                        minWidth: 250,
                        width: '250px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'dealer_unique_id',
                        title: 'КССС',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'amount',
                        title: 'Сумма',
                        minWidth: 200,
                        width: '200px',
                        filter: 'numeric',
                        type: 'numeric',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        minWidth: 200,
                        width: '200px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'product_agency_name',
                        title: 'Агентство по товарам',
                        minWidth: 400,
                        width: '400px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'delivery_address',
                        title: 'Адрес доставки',
                        minWidth: 400,
                        width: '400px',
                        filter: 'text',
                        type: 'text',
                    },
                    {
                        field: 'comment',
                        title: 'Комментарий',
                        minWidth: 400,
                        width: '400px',
                        filter: 'text',
                        type: 'text',
                    },
                ],
                orderStatuses: OrderStatuses,
            };
        },
        methods: {
            fetchData: api.orders.getDealerProductOrders,
            ...mapActions('points_flow_items', ['fetchDealerListShort']),
            async cancelOrder(id) {
                const { isConfirmed } = await showConfirm(
                    'Вы уверены, что хотите отменить данный заказ?'
                );

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.orders.cancelDealerProductOrder(id);
                    showMessage(status, message);
                }

                await this.getData();
            },
            selectStatus({ value }) {
                if (value.key) {
                    this.setFilter('status', 'eq', value.key);
                } else {
                    this.removeFilter('status');
                }

                this.updateTable(true);
            },
            dateChangeHandler({ value }) {
                this.value.start = value.start
                this.value.end = value.end
                this.removeFilter('dealer_id');
                const dateStart = new Date(value.start).toLocaleDateString('ru-RU');
                const dateEnd = new Date(value.end).toLocaleDateString('ru-RU');
                const dateToday = new Date(Date.now()).toLocaleDateString('ru-RU');

                if (dateStart === dateEnd) {
                    this.removeFilter('date');
                    this.setFilter('date', 'gtedatetime', dateStart);
                    this.updateTable(true);
                    return;
                }

                const obj = [
                    {
                        field: 'date',
                        operator: 'gte',
                        value: dateStart + 'T00:00:00',
                    },
                    {
                        field: 'date',
                        operator: 'lte',
                        value: dateEnd === '01.01.1970' ? dateToday : dateEnd + 'T23:59:59',
                    },
                ];
                this.setMultipleFilter('date', obj);
                this.updateTable(true);
            },
            clearDates() {
                this.value.start = null
                this.value.end = null
                this.clearFilters()
                this.updateTable(true);
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id);
                } else {
                    this.removeFilter('dealer_id');
                }

                this.updateTable(true);
            },
            async downloadReport() {
                try {
                    const response =
                        await api.orders.getProductOrdersGeneralReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        );

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        'download',
                        `Подробная статистика по заказам.xlsx`
                    );
                    document.body.appendChild(fileLink);

                    fileLink.click();
                } catch (e) {
                    console.warn(e);
                }
            },
        },
        computed: {
            ...mapGetters('points_flow_items', ['dealersListShort']),
            ...mapGetters('orders', ['getProductOrderStatusNameByKey']),
            dealerFilter() {
                return [...this.dealersListShort];
            },
            statusFilter() {
                return [...this.orderStatuses];
            },
        },
    };
</script>

<style lang="sass" scoped>
    .dealer-orders-table
      margin-top: 20px

    .actions-btn
      padding: 3px 10px !important

      &.btn::after
        content: none !important
</style>
